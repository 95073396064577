<template>
  <div class="d-flex justify-content-center pt-4">
    <div class="d-flex align-items-baseline">
      <b-spinner small label="Signing out"></b-spinner>
      <h4 class="ml-2">Signing out..</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountLogout',
  components: {},
  data() {
    return {};
  },
  methods: {
    signOut() {
      this.$http
        .get('/account/logout')
        .then(() => {
          this.$store.commit('signOut');
          this.$router.push('/account/login');
        })
        .catch((err) => {
          this.$toast.error(`Failed to logout: ${err.response.text}`);
        });
    },
  },
  created() {
    this.signOut();
  },
};
</script>

<style lang="scss" scoped>
</style>
